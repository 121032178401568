<template>
  <div class="profile-master-wrapper">
    <Breadcrumb
      v-if="this.$store.getters.getTenderLoaded"
      :breadcrumbs="breadcrumbs"
      :newPath="this.$store.getters.getTender.name"
      :mainLabelName="this.$store.getters.getTender.name"
    ></Breadcrumb>
    <a-tabs  @tabClick="readMessages">
      <a-tab-pane key="1" tab="Тендер">
        <Loader  v-if="!this.$store.getters.getTenderLoaded ||
              !this.$store.getters.getMasterAlreadyRespondedLoaded || !this.$store.getters.getPersonalDataStatusLoading" />
        <TabViewContainer>
          <div
            v-if="
              this.$store.getters.getTenderLoaded &&
              this.$store.getters.getMasterAlreadyRespondedLoaded && this.$store.getters.getPersonalDataStatusLoading
            "
            class="tender_cell__wrapper"
          >
            <MasterTenderDescription
              :draftTender="this.$store.getters.getTender"
              :subTags="this.$store.getters.getSubTags"
              :master="this.$store.getters.getPersonalData"
            ></MasterTenderDescription>
          </div>
        </TabViewContainer>
      </a-tab-pane>

      <a-tab-pane
        v-if="this.$store.getters.getMasterAlreadyResponded"
        key="2"
        tab="Сообщения"
      >
        <Loader v-if="!this.$store.getters.getTenderLoaded" />
        <TabViewContainer>
          <div class="tender_cell__wrapper" v-if="this.$store.getters.getTenderLoaded">
            <Chat
              :tenderUUID="this.$store.getters.getTender.uuid"
              :userUUID="this.$store.getters.getTender.customer.uuid"
              @closeDialogEvent="closeDialogEvent"
            ></Chat>
          </div>
        </TabViewContainer>
      </a-tab-pane>
      <!-- <a-tab-pane
        v-if="this.$store.getters.getMasterAlreadyResponded"
        key="3"
        tab="Отзыв"
      >
        <TabViewContainer>
           <MasterResponse :response="this.$store.getters.getResponse"></MasterResponse>
        </TabViewContainer>
      </a-tab-pane> -->
    </a-tabs>
  </div>
</template>

<script>
import MasterTenderDescription from '@/components/MasterComponents/MasterTenderDescription.vue'
// import MasterResponse from '@/components/MasterComponents/MasterResponse.vue'
import TabViewContainer from '@/components/common/TabViewContainer.vue'
import Chat from '@/components/Chat/Chat.vue'
import Breadcrumb from '@/components/common/Breadcrumb.vue'
import axios from 'axios'
import { getBearer, getUUID } from '@/helpers/authHelper'
import Loader from '@/components/common/Loader.vue'

export default {
  props: ['uuid'],
  components: {
    MasterTenderDescription,
    TabViewContainer,
    Breadcrumb,
    Chat,
    Loader
    // MasterResponse
  },
  data () {
    return {
      breadcrumbs: [
        {
          id: 1,
          name: 'Главная',
          link: '/main'
        }
      ],

      tender: this.$store.getters.getTender
    }
  },
  mounted () {
    this.$store.dispatch('setTender', this.$route.params.uuid)
    // this.$store.dispatch('setResponse', this.uuid)
    this.$store.dispatch('setSubTags')
    this.$store.dispatch('setMasterAlreadyResponded', this.$route.params.uuid)
    this.$store.dispatch('setPersonalData')
  },
  methods: {
    readMessages (e) {
      if (e === '2') {
        let sendData
        sendData = {
          userId: getUUID(),
          tenderId: this.uuid
        }
        axios
          .post(process.env.VUE_APP_SERVER_URL + '/read-all', sendData, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: getBearer()
            }
          })
          .then((res) => {
            this.$notification.open({
              message: 'Вы прочитали все сообщения'
            })
          })
          .catch(function (err) {
            this.$notification.open({
              message: 'Запрос на прочтение сообщений не прошел',
              description: err
            })
          })
      }
    },
    closeDialogEvent () {
      this.isOpenChat = false
    }
  }
}
</script>

<style>
.profile-master-wrapper {
  background-color: white;
  width: 100%;
  height: 100%;
}

.profile-label {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}

.profile-master-personal-tab-container {
  background-color: #f6f6f6;
  padding-right: 15%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 20px;
  padding-top: 17px;
  padding-bottom: 15%;
}

.tender_cell__wrapper{
  width: 100%;
}
</style>
