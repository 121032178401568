<template>
  <div class="master__tender__description">
    <TenderDesc :draftTender="draftTender" :subTags="subTags"></TenderDesc>
    <div class="master__tender__description__buttons__container">

      <div v-if="isOrderAccept && isTariff && this.$store.getters.getOrdersLoaded">
        <div @click="toggleOrderModal">
          <Button buttonClass="red-antd-button-without-width">
            Откликнуться
          </Button>
        </div>
      </div>
      <div v-if="isOrderAccept && !isTariff && this.$store.getters.getOrdersLoaded">
        <div @click="toggleTariffModal">
          <Button buttonClass="red-antd-button-without-width">
            Откликнуться
          </Button>
        </div>
      </div>
    </div>
    <a-modal :footer="null" :visible="orderVisible" @cancel="toggleOrderModal">
      <OrderRespondModal
        :clearCheckingInputs="checkingInputs"
        :clearForm="form"
        @okButtonOrderModule="toggleOrderModal"
      ></OrderRespondModal>
    </a-modal>
    <a-modal :footer="null" :visible="isModalTariffVisible" @cancel="toggleTariffModal">
      <div class="tariff-modal-top">
        Привет! Твой тарифный план не позволяет откликаться на этот тендер, его нужно повысить
      </div>
      <div class="tariff-modal-buttons">
        <div @click="linkTariff">
          <Button buttonClass="white-antd-button-without-width">
            Изменить Тарифный план
          </Button>
        </div>
        <div @click="toggleTariffModal">
          <Button buttonClass="red-antd-button-without-width">
            Отмена
          </Button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { getBearer, isMaster } from '@/helpers/authHelper'

import OrderRespondModal from '@/components/modal/OrderRespondModal.vue'
import Button from '@/components/common/Button.vue'
import TenderDesc from '@/components/Tender/TenderDesc'
import {isArray} from "ant-design-vue/lib/_util/vue-types/utils";

export default {
  props: ['draftTender', 'subTags', 'master'],
  components: {
    OrderRespondModal,
    Button,
    TenderDesc
  },
  async beforeMount() {
    await this.$store.dispatch('setOrders')
  },
  data () {
    return {
      orderVisible: false,
      isModalTariffVisible: false,
      customUrl: process.env.VUE_APP_SERVER_URL,
      isMaster: isMaster(),
      checkingInputs: {
        statusText: 'success',
        helpText: '',
        statusStartMoney: 'success',
        helpStartMoney: '',
        statusEndMoney: 'success',
        helpEndMoney: '',
        statusDate: 'success',
        helpDate: ''
      },
      form: {
        tenderId: this.draftTender.uuid,
        files: [],
        moment: [],
        planned_end_date: '',
        planned_end_price: '',
        planned_start_date: '',
        planned_start_price: '',
        text: ''
      }
    }
  },
  computed: {
    isTariff() {
      console.log(this.$store.getters.getOrders)
      console.log(this.draftTender.subtagsId)
      let can = false
      if (this.$store.getters.getOrders && this.$store.getters.getOrders.length > 0) {
        for (let orders of this.$store.getters.getOrders) {
          for(let sub of orders.subtags) {
            if (this.draftTender.subtagsId.includes(sub.id)){
              can = true
            }
          }
        }
      }
      return can
    },
    isOrderAccept () {

      if (
        this.draftTender.status.id === 1 &&
        !this.$store.getters.getMasterAlreadyResponded
      ) {
        return true
      }
      return false
    }
  },
  methods: {
    getDraftTenderSubtags () {
      const filterArr = this.subTags.filter((subtag) => {
        for (let id of this.draftTender.subtagsId) {
          if (id === subtag.id) {
            return subtag
          }
        }
      })

      return filterArr
    },
    toggleOrderModal () {

      this.checkingInputs.statusText = 'success'
      this.checkingInputs.helpText = ''
      this.checkingInputs.statusStartMoney = 'success'
      this.checkingInputs.helpStartMoney = ''
      this.checkingInputs.statusEndMoney = 'success'
      this.checkingInputs.helpEndMoney = ''
      this.checkingInputs.statusDate = 'success'
      this.checkingInputs.helpDate = ''
      this.form.files = []
      this.form.moment = []
      this.form.planned_end_date = ''
      this.form.planned_end_price = ''
      this.form.planned_start_date = ''
      this.form.planned_start_price = ''
      this.form.text = ''
      this.orderVisible = !this.orderVisible
    },

    toggleTariffModal() {
      this.isModalTariffVisible = !this.isModalTariffVisible;
    },

    linkTariff() {
      this.$router.push('/tariff')
    },

    deactivateTender () {
      const url = process.env.VUE_APP_SERVER_URL + `/tenders/${this.draftTender.uuid}`
      fetch(url, {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: getBearer()
        },
        body: JSON.stringify({ statusId: 3 })
      })
        .then((response) => response.json())
        .then((res) => {
          this.$notification.open({
            message: 'Вы завершили тендер!',
            description: res.message
          })
        })

        .catch((error) => {
          this.$notification.open({
            message: 'Вы не смогли завершить тендер!',
            description: error
          })
        })
    }
  }
}
</script>

<style lang="scss">
.master__tender__description {
  display: flex;
  flex-direction: column;
  padding: 26px 20px 40px 30px;
  justify-content: space-around;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;

  background-color: white;
  border-radius: 3px;
  &__date__and__price__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__date {
    color: #989898;
    text-align: left;
  }

  &__price {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: right;
    color: black;
  }
  &__image {
    margin-top: -45px;
    margin-bottom: 60px;
  }

  &__name__and__status__container {
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: black;
    margin-bottom: 30px;
  }

  &__pre-word-status {
    display: flex;
    flex-direction: row;
  }

  &__status {
    &__1 {
      color: #7fb56c;
    }
    &__2 {
      color: #b5b46c;
    }
    &__3 {
      color: #fa491c;
    }
  }

  &__services-work {
    margin-top: -25px;
    margin-bottom: 30px;
  }

  &__description {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    padding-right: 35%;
    margin-bottom: 20px;
    margin-top: -25px;
  }
  &__buttons__container {
    display: flex;
    justify-content: flex-start;
  }
  &__button {
    margin-right: 12px;
  }
  &__to__response{
    display: flex;
    &__label{
      margin-right: 2px;
    }
  }
}

@media (max-width: 992px) {
  .master__tender__description {
    &__date__and__price__container {
      flex-direction: column;
    }
    &__date {
      margin-bottom: 20px;
    }
    &__price {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 20px;
    }
  }
}

.tariff-modal-top{
  margin-top: 20px;
}

.tariff-modal-buttons{
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
}
</style>
